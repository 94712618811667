/* eslint-disable no-magic-numbers */
'use strict';

import React from 'react';
import { FormattedDate, FormattedRelative, FormattedMessage } from 'react-intl';

const MS_PER_DAY = 86400000;
const MS_PER_HOUR = 3600000;
const MS_PER_MINUTE = 60000;
const MS_PER_SECOND = 1000;

const COUNTDOWN_INTERVAL = 1000;

const HOURS_IN_A_DAY = 24;
const MINUTES_IN_AN_HOUR = 60;
const SECONDS_IN_A_MINUTE = 60;

const PAD_LENGTH = 2; // Length for padStart
const PAD_CHAR = '0'; // Character to pad with

const TWENTY_FIVE = 25;
const SEVENTY_FIVE = 75;

export const dayNameNumbers = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
};
export const dayNames = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday',
    'Thursday', 'Friday', 'Saturday'
];
const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const decimalCount = 10;
const NO_DAY = 0;
const ONE_DAY = 1;
const SEVEN_DAYS = 7;
const MONTH_THRESHOLD = 9;

const translations = {
    today: <FormattedMessage id="today" />,
    yesterday: <FormattedMessage id="yesterday" />
};

function utcDateDiff(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes());

    return Math.abs(utc2 - utc1);
}

function utcDaysDiff(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.abs(utc2 - utc1);
}

function daysDiff(a, b) {
    return Math.floor(utcDateDiff(a, b) / MS_PER_DAY);
}

function daysOnlyDiff(a, b) {
    return Math.floor(utcDaysDiff(a, b) / MS_PER_DAY);
}

function hoursDiff(a, b) {
    return Math.floor(utcDateDiff(a, b) / MS_PER_HOUR);
}

function minutesDiff(a, b) {
    return Math.floor(utcDateDiff(a, b) / MS_PER_MINUTE);
}

function daysUntilNow(date, isDaysOnlyDiff = false) {
    return isDaysOnlyDiff ? daysOnlyDiff(new Date(), new Date(date)) : daysDiff(new Date(), new Date(date));
}

function hoursUntilNow(date) {
    return hoursDiff(new Date(), new Date(date));
}

function minutesUntilNow(date) {
    return minutesDiff(new Date(), new Date(date));
}

function formatDate(dateObj, format) {
    const date = dateObj.getDate();
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();

    const monthNum = month + 1;
    const DD = date >= decimalCount ? date : `0${date}`;
    const MM = monthNum >= decimalCount ? monthNum : `0${monthNum}`;
    const YY = String(year).substr(2);
    const hh = hours >= decimalCount ? hours : `0${hours}`;
    const mm = minutes >= decimalCount ? minutes : `0${minutes}`;
    const ss = seconds >= decimalCount ? seconds : `0${seconds}`;

    let formattedDate = '';

    switch (format) {
        case 'DD/MM/YY': {
            formattedDate = `${DD}/${MM}/${YY}`;
            break;
        }
        case 'Day, DD Month': {
            formattedDate = `${dayNames[dateObj.getDay()]}, ${DD} ${monthNames[month]}`;
            break;
        }
        case 'DD': {
            formattedDate = `${DD}`;
            break;
        }
        case 'Day': {
            formattedDate = `${dayNames[dateObj.getDay()]}`;
            break;
        }
        case 'DD Month': {
            formattedDate = `${DD} ${monthNames[month]}`;
            break;
        }
        case 'DD Month YYYY': {
            formattedDate = `${DD} ${monthNames[month]} ${year}`;
            break;
        }
        case 'DD Month YYYY hh:mm': {
            formattedDate = `${DD} ${monthNames[month]} ${year} ${hh}:${mm}`;
            break;
        }
        case 'YYYY-MM-DD hh:mm': {
            formattedDate = `${year}-${MM}-${DD} ${hh}:${mm}`;
            break;
        }
        case 'YYYY-MM-DD':
            formattedDate = `${year}-${MM}-${DD}`;
            break;
        case 'MM/DD/YYYY':
            formattedDate = `${MM}/${DD}/${year}`;
            break;
        case 'YYYY-MM-DDThh:mm:ss+05:30': {
            formattedDate = `${year}-${MM}-${DD}T${hh}:${mm}:${ss}+05:30`;
            break;
        }

        case 'DD Mon':
            formattedDate = `${DD} ${monthNames[month].slice(0, 3)}`;
            break;

        case 'DD Mon, YYYY':
            formattedDate = `${DD} ${monthNames[month].slice(0, 3)}, ${year}`;
            break;

        case 'DD-MON-YY':
            // eslint-disable-next-line no-magic-numbers
            formattedDate = `${DD}-${monthNames[month].slice(0, 3).toUpperCase()}-${YY}`;
    }

    return formattedDate;
}

function computeDateForSafari(originalDate) {
    const data = originalDate.split('T');
    const parsedDate = new Date(data[0]);
    const month = parsedDate.getMonth();
    const computedDate = `${month < MONTH_THRESHOLD ? `0${month + 1}` : month + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;

    return new Date(`${computedDate} ${data[1]}`);
}

/**
 * @param {boolean} [onlyDateDiff=false] - If true, calculates the difference considering only the day, ignoring hours, minutes, and seconds.
 */

function relativeDate(date, dateFormat, props = {}, onlyDayDiff = false) {
    dateFormat = dateFormat || { month: 'short', day: '2-digit' };

    let currentDate = new Date(date);

    if (date && currentDate.toString().toLowerCase() === 'invalid date') {
        currentDate = computeDateForSafari(date);
    }

    const today = new Date();
    const diffFn = onlyDayDiff ? daysOnlyDiff : daysDiff;
    const diff = diffFn(currentDate, today);

    if (diff > ONE_DAY && diff < SEVEN_DAYS) {
        return (
            <FormattedRelative value={ currentDate } style="numeric">
                {date => (
                    <span { ...props }>{date}</span>
                )}
            </FormattedRelative>
        );
    }

    switch (diff) {
        case NO_DAY:
            return translations.today;
        case ONE_DAY:
            return translations.yesterday;
        default:
            return (
                <FormattedDate value={ currentDate } { ...dateFormat }>
                    {date => (
                        <span { ...props }>{date}</span>
                    )}
                </FormattedDate>
            );
    }
}

function getMaxMinDateValidity(maxYear, minYear) {
    const maxDate = new Date();

    maxDate.setFullYear(maxDate.getFullYear() + maxYear);

    const minDate = new Date();

    minDate.setFullYear(minDate.getFullYear() - minYear);

    return {
        maxDate,
        minDate
    };
}

function getFormattedTime(timeString, hideZeroes = false, globalTimeNeeded = false) {
    const hourEnd = timeString.indexOf(':');
    const H = Number(timeString.substr(0, hourEnd));
    const M = Number(timeString.substr(hourEnd + 1, hourEnd.length));
    const h = H % 12 || 12;
    const ampm = (H < 12 || H === 24) ? ' am' : ' pm';

    if (globalTimeNeeded) {
        timeString = H + (M === 0 && hideZeroes ? '' : timeString.substr(hourEnd, 3));
    }
    else {
        timeString = h + (M === 0 && hideZeroes ? '' : timeString.substr(hourEnd, 3)) + ampm;
    }

    return timeString;
}
/**
 * Returns Date Object with adding hour and minutes
 * @param {str} timeString - expects hh:mm
 * @returns Date Object
 */
function getTimeToFormat(timeString) {
    const hourEnd = timeString.indexOf(':');
    const hours = Number(timeString.substr(0, hourEnd));
    const minutes = Number(timeString.substr(hourEnd + 1, hourEnd.length));

    return new Date(0, 0, 0, hours, minutes);
}

function removeYears(date, years) {
    return new Date(date.setFullYear(date.getFullYear() - years));
}

/**
*
* @param {string(format YYYY-MM-DD)} dateString
* @returns { boolean }
*/
function isPastDate(dateString) {
    const now = new Date();

    return new Date(dateString).setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);
}

export {
    daysDiff,
    hoursDiff,
    minutesDiff,
    daysUntilNow,
    hoursUntilNow,
    minutesUntilNow,
    formatDate,
    relativeDate,
    getMaxMinDateValidity,
    getFormattedTime,
    getTimeToFormat,
    removeYears,
    isPastDate,
    TWENTY_FIVE,
    SEVENTY_FIVE,
    MS_PER_DAY,
    MS_PER_HOUR,
    MS_PER_MINUTE,
    MS_PER_SECOND,
    COUNTDOWN_INTERVAL,
    HOURS_IN_A_DAY,
    MINUTES_IN_AN_HOUR,
    SECONDS_IN_A_MINUTE,
    PAD_LENGTH,
    PAD_CHAR
};
